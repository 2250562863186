import "./index.scss";

const LevelSelection = ({startLevel, selectLevel, handleOnMouseOver, handleBackToPrevious}) => {
    
    return(
        <div className={`level-selection ${startLevel ? 'show' : ''}`}>
            <div className="level-selection-header">
                <div className="level-selection-header_left"  onMouseEnter={handleOnMouseOver} onClick={handleBackToPrevious}>
                    <img src="./assets/back_btn.png"></img> <span>Back</span> 
                </div>
                <div className="level-selection-header_right">
                    <img src="./assets/select_level_title.png"></img>
                </div>
            </div>
            <div className="level-selection-body">
                <div className="level-selection-body_item" onClick={() => selectLevel("easy")} onMouseEnter={handleOnMouseOver}><img src="./assets/level_easy.png"></img></div>
                <div className="level-selection-body_item" onClick={() => selectLevel("advance")} onMouseEnter={handleOnMouseOver}><img src="./assets/level_advance.png"></img></div>
            </div>
            
        </div>
    )
}

export default LevelSelection;