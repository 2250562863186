import "./index.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const AvatarSelection = ({start, selectAvatar, handleOnMouseOver, handleBackToPrevious,resetModalShow, handleResetModal, setResetModalShow}) => {
    return(
        <div className={`avatar-selection ${start ? 'show' : ''}`}>
            <div className="avatar-selection-header">
                <div className="avatar-selection-header_left"  onMouseEnter={handleOnMouseOver} onClick={handleBackToPrevious}>
                    <img src="./assets/back_btn.png"></img> <span>Back</span> 
                </div>
                <div className="avatar-selection-header_right">
                    <img src="./assets/select_avatar_title.png"></img>
                </div>
            </div>
            <div className="avatar-selection-body">
                <div className="avatar-selection-body_item" onClick={() => selectAvatar("Male")} onMouseEnter={handleOnMouseOver}><img src="./assets/male.png"></img></div>
                <div className="avatar-selection-body_item" onClick={() => selectAvatar("Female")} onMouseEnter={handleOnMouseOver}><img src="./assets/female.png"></img></div>
            </div>
            <div className={`reset-modal ${resetModalShow ? 'show' : ''}`}>
               
                <div className="reset-modal_container">
                    <div className="reset-modal_close"><FontAwesomeIcon className='icon' icon={solid('xmark')} onClick={() => setResetModalShow(false)}/></div>
                    <div className="title">Continue or Reset?</div>
                    <div className="text">Click continue to continue play from the latest scene</div>
                    <div className="buttons">
                    <div className="buttons_reset" id="reset" onClick={() => handleResetModal("reset")} onMouseEnter={handleOnMouseOver}>Reset</div>
                    <div className="buttons_continue" id="next" onClick={() => handleResetModal("continue")} onMouseEnter={handleOnMouseOver}>Continue</div>
                    
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default AvatarSelection;