import "./index.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useEffect, useState } from "react";

const Gameplay = ({setCurrentScene, handleChangeFrame, sceneData, lastGame, username, frameSrc, handleRestartGame, handleExitGame, restartModalShow, setRestartModalShow, exitModalShow, setExitModalShow, pauseModalShow, setPauseModalShow,  setFrameSrc, type, level, handleLogout}) => {
    
    const [ isMenuShown, setMenuShown ] = useState(false);
    const handleShowDropdownMenu = (id) => {
        var divsToHide = document.getElementsByClassName("menu-dropdown"); //divsToHide is an array
        for(var i = 0; i < divsToHide.length; i++){
            divsToHide[i].style.display = "none"; // depending on what you're doing
        }
        document.getElementById(id).style.display = "block";
    }

    const handleToggleDropdownMenu = (id) => {
        var divsToHide = document.getElementsByClassName("menu-dropdown"); //divsToHide is an array
        for(var i = 0; i < divsToHide.length; i++){
            divsToHide[i].style.display = "none"// depending on what you're doing
        }
        if(isMenuShown){
            document.getElementById(id).style.display = "none";
            setMenuShown(false)
        }else{
            document.getElementById(id).style.display = "block";
            setMenuShown(true)
        }
    }

    const handleHideDropdownMenu = (id) => {
        document.getElementById(id).style.display = "none";
    }

    useEffect(() => {
        if(parseInt(lastGame)<7){
            type == "Male" ? level == "advance" ? setFrameSrc("./Male_advance/index.html#start") : setFrameSrc("./Male_easy/index.html#start") : level > 0 ? setFrameSrc("./Female_advance/index.html#start") : setFrameSrc("./Female_easy/index.html#start");
            setTimeout(() => {
                type == "Male" ? level == "advance" ? setFrameSrc("./Male_advance/index.html#"+lastGame) : setFrameSrc("./Male_easy/index.html#"+lastGame) : level > 0 ? setFrameSrc("./Female_advance/index.html#"+lastGame) : setFrameSrc("./Female_easy/index.html#"+lastGame);  
                setCurrentScene(lastGame)
            }, 1000);
        }
    },[])

    return (
        <div className={`gameplay ${frameSrc.length > 0 ? "show" : ""}`}>
            <div className="header-menu">
                <div className="header-menu_container">
                    <div className="header-menu_left"> <a onClick={() => window.location.reload()}>Communications & Social Relationship Conversations</a></div>
                    <div className="header-menu_right">
                    <div className="header-menu_right__item menu">
                            <a href="#" onMouseOver={() => handleShowDropdownMenu("menu")} onClick={() => handleToggleDropdownMenu("menu")}><FontAwesomeIcon className='icon' icon={solid('bars')} />Menu</a>
                            <div className="menu-dropdown" id="menu" onMouseLeave={() => handleHideDropdownMenu("menu")}>
                                {
                                    
                                    sceneData.map(scene => (
                                        
                                       parseInt(lastGame) < parseInt(scene.id)) ? <div className="menu-dropdown_item main-menu hidden" key={scene.id}>Scene 0{scene.id} - {scene.title}</div> : <div className="menu-dropdown_item main-menu" key={scene.id} onClick={() => handleChangeFrame(scene.id)}>Scene 0{scene.id} - {scene.title}</div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="header-menu_right__item menu">
                            <img src="./lvl.png" /> {level}
                        </div>
                        
                        <div className="header-menu_right__item menu">
                           <FontAwesomeIcon className='icon' icon={solid('user')} /><span className="name">{username}</span>
                            
                        </div>
                    </div>
                </div>

            </div>
            <iframe id="gameIframe" className="gameplay-frame" title="MINDS Gameplay" src={frameSrc} />
            <div className="gameplay-footer">
                <div className="gameplay-footer_container">

                    <div className="gameplay-footer_left">&copy; {new Date().getFullYear()} - MINDSG Ltd.</div>
                    <div className="gameplay-footer_right">
                        {/* <div className="gameplay-footer_right__item">
                        <div className={`restart-dialog ${restartModalShow ? 'show' : ''}`} id="restart-modal">
                            <div className="restart-dialog_container">
                            <div className="text">Are you sure want to restart the section?</div>
                            <div className="button_container">
                                <div className="button_container__item"><a href="#" id="yes" onClick={handleRestartGame}>Yes</a></div>
                                <div className="button_container__item"><a href="#" id="no" onClick={() => setRestartModalShow(false)}>No</a></div>
                            </div>
                            </div>
                            <div className="caret_container">
                            <div className="caret"></div>
                            </div>
                        </div>
                        <a href="#" id="restart" onClick={() => setRestartModalShow(!restartModalShow)}><FontAwesomeIcon className='icon' icon={solid('rotate-right')} />Restart</a>

                        </div> */}
                        
                        <div className="gameplay-footer_right__item hidden play"><a href="#" className="play-game" id="play"><FontAwesomeIcon className='icon' icon={solid('play')} />Play</a></div>
                        <div className="gameplay-footer_right__item">
                        <div className={`restart-dialog exit ${exitModalShow ? 'show' : ''}`} id="exit-modal">
                            <div className="restart-dialog_container">
                            <div className="text">Are you sure want to logout?</div>
                            <div className="button_container">
                                <div className="button_container__item" onClick={handleExitGame}><a href="#" id="yes-exit">Yes</a></div>
                                <div className="button_container__item"  onClick={() => setExitModalShow(false)}><a href="#" id="no-exit">No</a></div>
                            </div>
                            </div>
                            <div className="caret_container">
                            <div className="caret"></div>
                            </div>
                        </div>
                        <a href="#" id="exit" onClick={() => setExitModalShow(!exitModalShow)}><FontAwesomeIcon className='icon' icon={solid('power-off')} />Logout</a>
                        </div>
                    </div>
                </div>

            </div>
            <div className={`pause-modal ${pauseModalShow ? 'show' : ''}`}>
                <div className="pause-modal_container">
                    <div className="title">Game paused!</div>
                    <div className="text">Click on play button to continue!</div>
                    <div className="button-play" id="continue" onClick={() => setPauseModalShow(false)}>Play</div>
                </div>
            </div>
           
        </div>
    )
}

export default Gameplay;