import React from 'react';
import './index.scss';

const Intro = ({setStart}) => {

    return (
        <div className='intro'>
            <img className='intro-logo' src='./assets/logo.png'></img>
            <img className='intro-title' src='./assets/title.png'></img>
            <img className='intro-line' src='./assets/line_intro.png'></img>
            <div className='intro-button' onClick={() => setStart(true)}><img src='./assets/btn_intro.png' /></div>
        </div>
    )
}

export default Intro;