import { useEffect, useState } from 'react';
import './App.scss';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import AvatarSelection from './components/AvatarSelection';
import LevelSelection from './components/LevelSelection';
import BlackScreen from './components/BlackScreen';
import Gameplay from './components/Gameplay';
import Intro from './components/Intro';
import Login from './components/Login';

function App() {
  const [start, setStart] = useState(false);
  const [show, setShow] = useState(false);
  const [showLevel, setShowLevel]= useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [isLogin, setLoggedIn] = useState(false);
  const [frameSrc, setFrameSrc] = useState("");
  const [type, setType] = useState();
  const [restartModalShow, setRestartModalShow ]= useState(false);
  const [exitModalShow, setExitModalShow ]= useState(false);
  const [pauseModalShow, setPauseModalShow ]= useState(false);
  const [level, setLevel] = useState();
  const [userId, setUserId] = useState(localStorage.getItem("userId")??"");
  const [lastGame, setLastGame] = useState();
  const [isLoginLoading, setLoginLoading] = useState(false);
  const [resetModalShow, setResetModalShow ] = useState(false);
  const [sceneData] = useState([
    {
      id: "1",
      title: "Waking Up from Bed"
    },
    {
      id: "2",
      title: "At the Grocery Store"
    },
    {
      id: "3",
      title: "At the Coffee Shop"
    },
    {
      id: "4",
      title: "At the Office"
    },
    {
      id: "5",
      title: "At the Canteen"
    },
    {
      id: "6",
      title: "On the Bus"
    },
    {
      id: "7",
      title: "At Home"
    }
  ])
  const [currentScene, setCurrentScene] = useState(1);

  const handleLogin = (userId) => {
    if (!isLoginLoading){
      setLoginLoading(true)
      document.getElementById("start-click").play();
      axios.post(process.env.REACT_APP_BASE_URL + '/apiauth/loginByUserId', {
          "userId":userId,
      }).then(async function (response) {
        if(response.data.result == "OK"){
          toast.success("Login Success !", {
            position: toast.POSITION.TOP_RIGHT
          });
          setLoggedIn(true);
          setShowLevel(true);
          setUserId(userId);
          setLoginLoading(false)
          localStorage.setItem("userId", userId);
        }else{
          setLoginLoading(false)
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        
      })
      .catch(function (error) {
        setLoginLoading(false)
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      })
    }
    
  }

  const checkLastGame = () => {
    if(userId!="" && level != "" && type != "" && userId && level && type){
      axios.get(process.env.REACT_APP_BASE_URL + "/apigamerecord/lastgame/"+ userId+"/"+level+"/"+type).then(async function (response) {
        if(response.data.result == "OK"){
          setLastGame(parseInt((response.data.data.scene)+1))
          if(parseInt((response.data.data.scene)+1) < parseInt(currentScene)){
            setShow(true);
            setFrameSrc("");
            setShow(false);
            setStart(false);
            setShowLevel(true);
            setCurrentScene(parseInt((response.data.data.scene)+1))
            toast.warning("Game Record had been changed", {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        }else if(response.data.message == "User does not exist any game record."){
          setLastGame(1)
          if(parseInt(currentScene) > 1){
            setShow(true);
            setFrameSrc("");
            setShow(false);
            setStart(false);
            setShowLevel(true);
            setCurrentScene(1);
            toast.warning("Game Record had been changed", {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        }
        
      }).catch(function (error) {
        console.log(error)
      })
    }
  }

  function getUserLastGame() {
    if(userId!="" && level != "" && type != ""){
      axios.get(process.env.REACT_APP_BASE_URL + "/apigamerecord/lastgame/"+ userId+"/"+level+"/"+type).then(async function (response) {
        if(response.data.result == "OK" && response.data.data.scene > 0){
          setLastGame(parseInt(response.data.data.scene)+1);
          localStorage.setItem("lastGame",parseInt(response.data.data.scene)+1);
          setResetModalShow(true)
        }else if(response.data.message == "User does not exist any game record." || response.data.data.scene == 0){
          setLastGame(1)
          type == "Male" ? level == "advance" ? setFrameSrc("./Male_advance/index.html#1") : setFrameSrc("./Male_easy/index.html#1") : level == "advance" ? setFrameSrc("./Female_advance/index.html#1") : setFrameSrc("./Female_easy/index.html#1");
          setCurrentScene(1);
        }
        
      }).catch(function (error) {
        console.log(error)
      })
    }
  }

  const handleResetModal = (typeChoose) => {
    document.getElementById("start-click").play()
    if (typeChoose == "continue"){
      setResetModalShow(false);
      type == "Male" ? level == "advance" ? setFrameSrc("./Male_advance/index.html#"+lastGame) : setFrameSrc("./Male_easy/index.html#"+lastGame) : level == "advance" ? setFrameSrc("./Female_advance/index.html#"+lastGame) : setFrameSrc("./Female_easy/index.html#"+lastGame);
      setCurrentScene(lastGame)
      
    }else{
      
      axios.post(process.env.REACT_APP_BASE_URL + "/apigamerecord/reset/"+userId, {
        "level" : level,
        "type" : type
      }).then(async function (response) {
        if(response.data.result == "OK"){
          setResetModalShow(false);
          setLastGame(1);
          type == "Male" ? level == "advance" ? setFrameSrc("./Male_advance/index.html#1") : setFrameSrc("./Male_easy/index.html#1") : level == "advance" ? setFrameSrc("./Female_advance/index.html#1") : setFrameSrc("./Female_easy/index.html#1");
          localStorage.setItem("lastGame",1);
          setCurrentScene(1)
          
        }
        
      }).catch(function (error) {
        console.log(error)
      })
    }
  }

  const handleLogout = () => {
    localStorage.removeItem("userId");
    window.location.reload()
  }

  const handleSelectAvatar = (Thistype) => {
    document.getElementById("start-click").play()
    setShow(true);
    setShow(false);
    setType(Thistype)
    if(Thistype == type){
      getUserLastGame();
    }
  }

  const handleSelectLevel = (level) => {
    document.getElementById("start-click").play()
    setShow(true);
    setLevel(level);
    setShow(false);
    setStart(true);
    setShowLevel(false);
  }

  const handleStartSession = () => {
    document.getElementById("start-click").play()
    isLogin ? setShowLevel(true) : setShowLogin(true)
  }

  const handleOnMouseOver = () => {
    if(!isLoginLoading){
      document.getElementById("start-hover").play()
    } 
    
  }

  const handleExitGame = () => {
    localStorage.removeItem("userId");
    window.location.reload();
  }

  const handleRestartGame = () => {
    setRestartModalShow(false)
    setFrameSrc("");
    type == "Male" ? level == "advance" ? setFrameSrc("./Male_advance/index.html#"+lastGame) : setFrameSrc("./Male_easy/index.html#"+lastGame) : level == "advance" ? setFrameSrc("./Female_advance/index.html#"+lastGame) : setFrameSrc("./Female_easy/index.html#"+lastGame);

  }

  const handleBackToPrevious = () => {
    setShow(true);
    setStart(false);
    setShowLevel(false);
    setShow(false);
    setShowLogin(false);
  }

  const handleBackToPreviousLevel = () => {
    setShow(false);
    setStart(false);

    setShowLevel(true);
  }

  const checkSceneAvailability = (scene) => {
    if(userId!=""){
      axios.get(process.env.REACT_APP_BASE_URL + "/apigamerecord/sceneexists/"+ userId+"/"+parseInt(scene)).then(async function (response) {
        if(response.data.result == "OK"){
          if(!response.data.exist){
            toast.warning("No Record Exist", {
              position: toast.POSITION.TOP_RIGHT
            });
          }else{
            type == "Male" ? level == "advance" ? setFrameSrc("./Male_advance/index.html#start") : setFrameSrc("./Male_easy/index.html#start") : level == "advance" ? setFrameSrc("./Female_advance/index.html#start") : setFrameSrc("./Female_easy/index.html#start");
            setTimeout(() => {
                type == "Male" ? level == "advance" ? setFrameSrc("./Male_advance/index.html#"+scene) : setFrameSrc("./Male_easy/index.html#"+scene) : level == "advance" ? setFrameSrc("./Female_advance/index.html#"+scene) : setFrameSrc("./Female_easy/index.html#"+scene);  
                setCurrentScene(scene)
            }, 1000);
          }
          
        }
        
      }).catch(function (error) {
        console.log(error)
      })
    }
  }

  const handleChangeFrame = (scene) => {
    var divsToHide = document.getElementsByClassName("menu-dropdown"); //divsToHide is an array
    for(var i = 0; i < divsToHide.length; i++){
        divsToHide[i].style.display = "none"; // depending on what you're doing
    }
    checkSceneAvailability(scene);
}


  useEffect (() => {
    if(localStorage.getItem("userId")){
      setLoggedIn(true);
      setUserId(localStorage.getItem("userId"));
    }
  },[])

  useEffect (() => {
    if(type){
      getUserLastGame();
    }
  }, [type])

  var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
  var eventer = window[eventMethod];
  var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

  // Listen to message from child window
  eventer(messageEvent,function(e) {
      var key = e.message ? "message" : "data";
      var data = e[key];
      if(data.type != "webpackWarnings" && data != "webpackHotUpdatedcbdef78c1481ad8249c"){
        var level = data.split(",")[0];
        setCurrentScene(parseInt(level));
        checkLastGame();
      }
      
  },false);

  return (
   <div className='container'>
    <Intro setStart={() => handleStartSession()}/>
    
    <div className={`inner-container ${showLogin || showLevel || start ? 'show' : ''}`}>
      <BlackScreen show={show}/>
      <Login isLoginLoading={isLoginLoading} handleLogin={handleLogin} isLogin={isLogin} handleOnMouseOver={() => handleOnMouseOver()}/>
      <LevelSelection startLevel={showLevel} selectLevel={handleSelectLevel} handleOnMouseOver={() => handleOnMouseOver()} handleBackToPrevious={handleBackToPrevious} />
      <AvatarSelection setResetModalShow={setResetModalShow} resetModalShow={resetModalShow} handleResetModal={handleResetModal} start={start} selectAvatar={handleSelectAvatar} handleOnMouseOver={() => handleOnMouseOver()} handleBackToPrevious={handleBackToPreviousLevel}/>
      <Gameplay handleChangeFrame={handleChangeFrame} setCurrentScene={setCurrentScene} sceneData={sceneData}  lastGame={lastGame} username={userId} handleLogout={handleLogout} frameSrc={frameSrc} level={level} setFrameSrc={setFrameSrc} type={type} handleExitGame={() => handleExitGame()} handleRestartGame={() => handleRestartGame()} restartModalShow={restartModalShow} exitModalShow={exitModalShow} pauseModalShow={pauseModalShow} setRestartModalShow={setRestartModalShow} setExitModalShow={setExitModalShow} setPauseModalShow={setPauseModalShow}/>
      <ToastContainer />
      <div className="footer">
        <div className="footer_text">Copyright &copy; {new Date().getFullYear()} - MINDSG Ltd.</div>
      </div>
      <audio id="start-hover" controls="" preload="auto" hidden>
          <source src="./assets/hover_button.mp3" controls="" />
          <source src="./assets/hover_button.ogg" controls="" />
      </audio>
      <audio id="start-click" controls="" preload="auto" hidden>
          <source src="./assets/Mouse Click Open.mp3" controls="" />
          <source src="./assets/Mouse Click Open.ogg" controls="" />
      </audio>
    </div>
    
   </div>
  );
}

export default App;
