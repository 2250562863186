import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import "./index.scss";
import { useState } from 'react';
import { ClipLoader } from 'react-spinners';
// import { useState } from 'react';

const Login = ({handleLogin, isLogin, handleOnMouseOver, isLoginLoading}) => {
    const [userId, setUserId] = useState("")
    // const [showPassword, setShowPassword] = useState(false);
    // const handleShowPassword = () => {
    //     setShowPassword(!showPassword);
    // }
    return(
        <div className={`login-container ${isLogin ? 'hide' : ''}`}>
            <div className="login">
                <div className="login-field">
                    <FontAwesomeIcon icon={solid('user')} />
                    <input type={"text"} placeholder="User ID" value={userId} onChange={(e) => setUserId(e.target.value)}/>
                </div>
                {/* <div className="login-field">
                    <FontAwesomeIcon icon={solid('lock')} />
                    <input className='login-password' type={showPassword ? 'text' : 'password'} placeholder="Password" />
                    <div className='hint' onClick={handleShowPassword}>{showPassword ? <FontAwesomeIcon icon={solid('eye-slash')} /> : <FontAwesomeIcon icon={solid('eye')} />}</div>
                </div> */}
                <div className={`login-button ${isLoginLoading ? 'loading' : ''}`} onClick={() => handleLogin(userId)} onMouseEnter={handleOnMouseOver}>{isLoginLoading ? <ClipLoader color="#fff" />  : <></>}Login</div>
            </div>
        </div>
       
    )
}

export default Login;